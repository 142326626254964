<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('category.create')"
      :title="$t('category.new')"
      @submitPressed="submitPressed"
    />
    <category-form :category-obj="category" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CategoryForm from './components/CategoryForm.vue';

export default {
  components: {
    TitleBar,
    CategoryForm
  },
  data() {
    return {
      action: null,
      category: {
        name: '',
        description: '',
        onOff: false,
        vissible: true,
        url: '',
        keywords: '',
        metaDescription: '',
        excerpt: '',
        parent: {},
        attachment: null,
        fileURL: null,
        tags: []
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
};
</script>
